import router from "@/router";
import i18n from "../../i18n";
const crypto = require("crypto");
export default {
    state: {
        showRecognitionPage: 'none',
        enableAllRecognition: false,
        enableSpeechRecognition: true,
        enableFaceDetection: true,
        speechRecognitionStatus: false,
        faceDetectionStatus: false,
        isUserApproached: false,
        isUserLooking: false,
        faceCount: 0,
        isAvatarListening: false,
        speechTranscript: '',
        speechRecognitionHandler: false,
        greetingStatus: false,
        resetKioskStatus: false,
        timeoutID: 0,
        completeUserSession: 0,
        touchCount: 0,
        hasCamera: false,
        hasMicrophone: false,
        speechRecognitionLanguage: 'en-US',
    },
    getters: {
        enableAllRecognition: state => state.enableAllRecognition,
        enableSpeechRecognition: state => state.enableSpeechRecognition,
        enableFaceDetection: state => state.enableFaceDetection,
        speechRecognitionStatus: state => state.speechRecognitionStatus,
        faceDetectionStatus: state => state.faceDetectionStatus,
        isAvatarListening: state => state.isAvatarListening,
        faceCount: state => state.faceCount,
        speechTranscript: state => state.speechTranscript,
        isUserLooking: state => state.isUserLooking,
        isUserApproached: state => state.isUserApproached,
        showRecognitionPage: state => state.showRecognitionPage,
        speechRecognitionHandler: state => state.speechRecognitionHandler,
        getGreetingStatus: state => state.greetingStatus,
        getResetKioskStatus: state => state.resetKioskStatus,
        getTouchCount: state => state.touchCount,
        hasCamera: state => state.hasCamera,
        hasMicrophone: state => state.hasMicrophone,
        speechRecognitionLanguage: state => state.speechRecognitionLanguage
    },
    mutations: {
        setEnableAllRecognition(state, status) {
            state.enableAllRecognition = status;
        },
        setEnableSpeechRecognition(state, status) {
            state.enablespeechRecognition = status;
        },
        setEnableFaceDetection(state, status) {
            state.enablefaceDetection = status;
        },
        setSpeechRecognitionStatus(state, status) {
            state.speechRecognitionStatus = status
        },
        setFaceDetectionStatus(state, status) {
            state.faceDetectionStatus = status
        },
        setIsAvatarListening(state, status) {
            state.isAvatarListening = status;
        },
        setFaceCount(state, count) {
            state.faceCount = count;
        },
        setSpeechTranscript(state, transcript) {
            state.speechTranscript = transcript;
        },
        setIsUserLooking(state, status) {
            state.isUserLooking = status
        },
        setIsUserApproached(state, status) {
            state.isUserApproached = status
        },
        setShowRecoginitionPage(state, status) {
            state.showRecognitionPage = status
        },
        setSpeechRecognitionHandler(state, status) {
            state.speechRecognitionHandler = status;
        },
        setGreetingStatus(state, status) {
            state.greetingStatus = status
        },
        setResetKioskStatus(state, status) {
            state.resetKioskStatus = status
        },
        setTimeoutID(state, value) {
            state.timeoutID = value
        },
        setTouchCount(state, count) {
            state.touchCount = count
        },
        setHasCamera(state, status) {
            state.hasCamera = status
        },
        setHasMicrophone(state, status) {
            state.hasMicrophone = status
        },
        setSpeechRecognitionLanguage(state, lang) {
            state.speechRecognitionLanguage = lang
        },
    },
    actions: {
        resetKiosk({ dispatch, commit, state, getters }) {
            if (router.currentRoute.path !== '/telepresence') {
                clearTimeout(state.timeoutId)
                //commit('setResetKioskStatus', true)
                state.timeoutId = setTimeout(function () {
                    commit('setSessionEndViewer', true)
                    state.completeUserSession = setTimeout(function () {
                        dispatch('kioskStartOver')
                    }, 15000)
                }, 30000)
            }
        },
        kioskStartOver({ commit, state, dispatch, getters }) {
            let date = new Date();
            if (router.currentRoute.path !== '/') {
                router.push('/').then(() => {
                    console.log('All good')
                })
            }
            i18n.locale = 'en'
            commit('setTouchCount', 0)
            commit('setResetKioskStatus', false)
            commit('setGreetingStatus', false)
            commit('closeOptionsViewer')
            commit('closeEmailViewer')
            commit('closePdfViewer')
            commit('closeImageViewer')

            dispatch("addSessionDataToStrapi", {
                applicationId: getters.getKioskProfile.data().applicationId,
                actionType: "Session Ended",
                applicationSessionId: getters.getSessionId,
                timeStamp: date.toISOString(),
                sessionPayload: {},
              });

              
            commit('setSessionEndViewer', false)
            commit("setCurrentLanguage", "en");
            commit('setEnableADA', false)
            dispatch("identifyAvatarLangCodes", "en");
            commit("setSpeechRecognitionLanguage", "en-US")
            dispatch('setDefaults')
            commit('setTextBubbleText', "Hi, my name is " + getters.getAvatarName + ", I’m here to help you. Tap the microphone button, and ask me ‘What can you do?’")
            console.log(getters.getSessionId, "what is the session ID here");
            

              

            clearTimeout(state.timeoutId)
            clearTimeout(state.completeUserSession)
            commit(
                "setSessionId",
                crypto.randomBytes(50).toString("hex").slice(0, 15)
              );
        },
        kioskStillThere({ commit, state }) {
            clearTimeout(state.timeoutId)
            clearTimeout(state.completeUserSession)
            commit('closeOptionsViewer')
            commit('setResetKioskStatus', false)
            commit('setSessionEndViewer', false)
            commit('setTextBubbleText', 'Tap on the microphone button, to ask me a question.')
        },
        kioskRedirected({commit,state,dispatch}){
            // commit('speechRecognitionStatus',false)
            commit("setIsSpeaking", false)
            commit('setTextBubbleText', 'Tap on the microphone button, to ask me a question.')
        },
        clearResetTimeout({ state }) {
            clearTimeout(state.timeoutId)
            clearTimeout(state.completeUserSession)
        },
        checkMediaDevices({ commit }) {

            navigator.getMedia =
                navigator.getUserMedia || navigator.webkitGetUserMedia;
            navigator.getMedia(
                { video: true },
                function () {
                    commit('setHasCamera', true)

                },
                function () {
                    commit('setHasCamera', true)

                }
            );

            navigator.getMedia(
                { audio: true },
                function () {
                    commit('setHasMicrophone', true)
                },
                function () {
                    commit('setHasMicrophone', true)
                }
            );
        }
    }
}
