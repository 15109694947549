<template>
  <v-row no-gutters align="center" justify="center" >
    <v-col cols="2"></v-col>
    <v-col cols="8">
      <v-row no-gutters align="center" justify="center">
        <v-col cols="3" align="center" v-for="(language, i) in getLanguages" :key="i" class="mx-3">
          <v-btn v-bind:color="getCurrentLanguage === language ? 'primary' : 'grey lighten-2'" class="btnDim" 
            height="60px" width="120px" @click="changeLanguage(language)"> {{ pullLanguage(language) }} </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col align="right" cols="2" style="margin-top: -15px;margin-left: -15px">
      <v-img src="@/assets/ada.png" width="125" contain @click="$store.commit('setEnableADA', true)"
        v-if="!getenableADA"></v-img>
      <v-btn fab color="#0443cc" v-else @click="$store.commit('setEnableADA', false); adaTracking()" width="80"
        height="80" class="ma-0 pa-0">
        <v-icon color="white" size="45">mdi-restore</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "languageBar",
  data() {
    return {
      languages: [
        { name: 'English', code: 'en' },
        { name: 'Spanish', code: 'es-US' },
      ]
    }
  },
  computed: {
    ...mapGetters(["getTouchCount", "getCurrentLanguage", "getLanguages", "getenableADA","getKioskProfile","getSessionId","getGreetingStatus"]),
  },
  methods: {
    pullLanguage(code) {
      console.log(this.languages.filter(lang => lang.code === code)[0].name,"This are languages pulled");
      return this.languages.filter(lang => lang.code === code)[0].name
    },
    adaTracking() {
      let date = new Date()
      this.$store.dispatch('addSessionDataToStrapi', {
                applicationId: this.getKioskProfile.data().applicationId,
                actionType: 'Touch',
                applicationSessionId: this.getSessionId,
                timeStamp: date.toISOString(),
                sessionPayload: {
                    module: "General",
                    action: "ADA",
                    response: "ADA enabled",
                    timeStamp: date.toISOString()
                }
            })

    },
    changeLanguage(language) {
      this.$store.commit('setRequestQna', false)
      this.$store.commit("setIsThinking", false)
      this.$store.commit("setSpeechRecognitionHandler", false);
      this.$store.commit('setTouchCount', this.getTouchCount + 1)
      console.log(this.getTouchCount,"this is the touch kiosk need");
      let date;
      if(this.getTouchCount === 1 && !this.getGreetingStatus){
          date = new Date()
          this.$store.dispatch('addSessionDataToStrapi', {
                  applicationId: this.getKioskProfile.data().applicationId,
                  actionType: 'Session Started',
                  applicationSessionId: this.getSessionId,
                  timeStamp: date.toISOString(),
                  sessionPayload: {
                    module: "Default",
                    action: "Touch Detected",
                    response: "User Session Started",
                    timeStamp: date.toISOString()
                  }
                })
      }
              
      
      switch (language) {
        case "en":
          this.$store.commit("setTouchCount", this.getTouchCount + 1);
          this.$i18n.locale = "en";
          this.$store.commit("setCurrentLanguage", "en");
          this.$store.dispatch("avatarSpeak", "I am now speaking in english.");
          this.$store.dispatch('setDefaults')
          this.$store.dispatch("identifyAvatarLangCodes", "en");
          this.$store.commit("setSpeechRecognitionLanguage", "en-US")
          date = new Date()
          this.$store.dispatch('addSessionDataToStrapi', {
                applicationId: this.getKioskProfile.data().applicationId,
                actionType: 'Touch',
                applicationSessionId: this.getSessionId,
                timeStamp: date.toISOString(),
                sessionPayload: {
                    module: "Language Bar",
                    action: "Change Language",
                    response: "Language changed to English",
                    timeStamp: date.toISOString()
                }
            })
          break;
        case "es-US":
          this.$i18n.locale = "es";
          this.$store.commit("setCurrentLanguage", "es-US");
          this.$store.dispatch('avatarSpeak', 'Ahora estoy hablando en español.');
          this.$store.dispatch('setDefaultsSpanish')
          this.$store.dispatch("identifyAvatarLangCodes", "es-US");
          this.$store.commit("setSpeechRecognitionLanguage", "es-US")
          // Track the touch count and action performed.
          this.$store.commit("setTouchCount", this.getTouchCount + 1);
          date = new Date()
          this.$store.dispatch('addSessionDataToStrapi', {
                applicationId: this.getKioskProfile.data().applicationId,
                actionType: 'Touch',
                applicationSessionId: this.getSessionId,
                timeStamp: date.toISOString(),
                sessionPayload: {
                    module: "Language Bar",
                    action: "Change Language",
                    response: "Language changed to Spanish",
                    timeStamp: date.toISOString()
                }
            })
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style scoped>
.btnDim {
  width: 90%;
  padding-top: 15px;
  padding-bottom: 15px;
}
</style>