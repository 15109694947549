var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{staticClass:"mt-3",attrs:{"no-gutters":"","align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',{staticClass:"d-flex justify-center pa-0"},[[_c('v-text-field',{staticClass:"text-h5",attrs:{"placeholder":"Enter a keyword to search for FAQ","hide-details":"","outlined":"","height":"55px","rounded":"","append-icon":_vm.searchBarIcon,"clear-icon":"mdi-close-circle"},on:{"click":function($event){return _vm.updateExpand()},"click:append":function($event){return _vm.clearSearch()}},model:{value:(_vm.searchKeyword),callback:function ($$v) {_vm.searchKeyword=$$v},expression:"searchKeyword"}}),(!_vm.getKioskProfile.data().physicalKeyboard)?[(!_vm.expand)?_c('v-icon',{staticClass:"ml-2",attrs:{"size":"35"},on:{"click":function($event){_vm.expand = !_vm.expand}}},[_vm._v("mdi-keyboard-outline")]):_c('v-icon',{staticClass:"ml-2",attrs:{"size":"35"},on:{"click":function($event){_vm.expand = !_vm.expand;
                  _vm.clearSearch();
                  _vm.searchId = '';}}},[_vm._v("mdi-keyboard-off-outline")])]:_vm._e()]],2),_c('v-expand-transition',[(_vm.expand)?_c('v-card-text',{staticClass:"d-flex justify-center ma-0 pa-0"},[_c('SimpleKeyboard',{staticClass:"keyboard",on:{"onKeyPress":_vm.onKeyPress,"onChange":_vm.onChange}})],1):_vm._e()],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"no-gutters":""}},[(!_vm.expand && _vm.searchKeyword === '' && _vm.searchId === '')?_c('v-col',{staticClass:"mt-5",attrs:{"cols":"3","align":"center"}},[_c('v-card',{staticClass:"containerBorder",attrs:{"width":"95%","height":_vm.cardHeight}},[_c('v-card-title',{staticClass:"justify-center primary--text"},[_vm._v(_vm._s(_vm.$t("categoryTitle")))]),_c('v-divider',{staticClass:"mx-3"}),_c('v-list',{staticClass:"overflow-y-auto",attrs:{"height":_vm.cardHeight - 80}},_vm._l((_vm.faqCategory),function(categoryName,i){return _c('v-list-item',{key:i},[_c('v-list-item-content',[_c('v-btn',{attrs:{"height":"45","color":_vm.getSelectedFaqCat.category === categoryName
                        ? 'secondary'
                        : 'primary'},on:{"click":function($event){return _vm.$store.commit('setSelectedFaqCat', {
                        category: categoryName,
                      })}}},[_vm._v(_vm._s(categoryName))])],1)],1)}),1)],1)],1):_vm._e(),_c('v-col',{staticClass:"mt-5",attrs:{"cols":_vm.colLenght,"align":"center"}},[_c('v-card',{staticClass:"containerBorder overflow-y-auto mb-2",attrs:{"width":"95%","height":_vm.cardHeight}},[(!_vm.expand && _vm.searchKeyword === '' && _vm.searchId === '')?_c('v-expansion-panels',{staticClass:"px-5 py-3",attrs:{"focusable":"","dark":"","popout":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},_vm._l((_vm.filteredFaqs(_vm.getSelectedFaqCat.category)),function(item,i){return _c('v-expansion-panel',{key:i,staticClass:"mt-2",attrs:{"value":item.question}},[_c('v-expansion-panel-header',{staticClass:"white--text text-h6",attrs:{"color":"primary"},on:{"click":function($event){return _vm.clickedFaq(item)}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-icon',{attrs:{"color":"white","large":""}},[_vm._v("mdi-arrow-down-bold-circle")])]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.question)+" ")]),_c('v-expansion-panel-content',{staticClass:"black--text pt-4 text-justify text-h6",attrs:{"color":"white"}},[(item.showMap || item.showUrl || item.showForm)?_c('v-row',{staticClass:"fill-height",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"9"}},[_c('span',{domProps:{"innerHTML":_vm._s(item.answer)}})]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-row',{attrs:{"no-gutters":""}},[(item.showForm)?_c('v-col',{attrs:{"align-self":"center","align":"center"}},[_c('v-btn',{staticClass:"mb-3",attrs:{"color":"secondary","width":"85%"},on:{"click":function($event){return _vm.showForm(item.formName)}}},[_c('v-icon',{attrs:{"left":"","dark":"","medium":""}},[_vm._v(" mdi-link-variant ")]),_vm._v(" "+_vm._s(item.formName)+" ")],1)],1):_vm._e(),(
                            item.showMap &&
                            _vm.getKioskProfile.data().kioskPremise === 'Inside'
                          )?_c('v-col',{attrs:{"align-self":"center","align":"center"}},_vm._l((_vm.assignedMaps(item.mapName)),function(map,i){return _c('div',{key:i},[_c('v-btn',{staticClass:"mb-3",attrs:{"color":"secondary","width":"85%"},on:{"click":function($event){return _vm.showMap(map)}}},[_c('v-icon',{attrs:{"left":"","dark":"","medium":""}},[_vm._v(" mdi-map-marker-radius ")]),_vm._v(" "+_vm._s(_vm.trimmedName(map))+" ")],1)],1)}),0):_vm._e(),(item.showUrl)?_c('v-col',{attrs:{"align-self":"center","align":"center"}},[_c('v-btn',{staticClass:"mb-3",attrs:{"color":"secondary","width":"85%"},on:{"click":function($event){return _vm.sendLink(
                                item.urlDetails.urllist[0].link,
                                item.question,
                                item.urlDetails.urllist[0].redirect
                              )}}},[_c('v-icon',{attrs:{"left":"","dark":"","medium":""}},[_vm._v(" mdi-link-variant ")]),_vm._v(" "+_vm._s(item.urlDetails && item.urlDetails.urllist && item.urlDetails.urllist.length > 0 ? _vm.trimmedName(item.urlDetails.urllist[0].name) : "Go to Website")+" ")],1)],1):_vm._e()],1)],1)],1):_c('v-row',[_c('v-col',[_c('span',{domProps:{"innerHTML":_vm._s(item.answer)}})])],1)],1)],1)}),1):_c('v-expansion-panels',{staticClass:"px-3",attrs:{"focusable":"","dark":"","popout":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_vm._l((_vm.matchingFaqs),function(item,i){return _c('v-expansion-panel',{key:i,staticClass:"mt-2",attrs:{"value":item.question}},[_c('v-expansion-panel-header',{staticClass:"white--text text-h6",attrs:{"color":"primary"},on:{"click":function($event){return _vm.clickedFaq(item)}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-icon',{attrs:{"color":"white","large":""}},[_vm._v("mdi-arrow-down-bold-circle")])]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.question)+" ")]),_c('v-expansion-panel-content',{staticClass:"black--text pt-4 text-justify text-h6",attrs:{"color":"white"}},[(item.showMap || item.showUrl || item.showForm)?_c('v-row',{staticClass:"fill-height",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"9"}},[_c('span',{domProps:{"innerHTML":_vm._s(item.answer)}})]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-row',{attrs:{"no-gutters":""}},[(
                            item.showMap &&
                            _vm.getKioskProfile.data().kioskPremise === 'Inside'
                          )?_c('v-col',{attrs:{"align-self":"center","align":"center"}},_vm._l((_vm.assignedMaps(item.mapName)),function(map,i){return _c('div',{key:i},[_c('v-btn',{staticClass:"mb-3",attrs:{"color":"secondary","width":"85%"},on:{"click":function($event){return _vm.showMap(map)}}},[_c('v-icon',{attrs:{"left":"","dark":"","medium":""}},[_vm._v(" mdi-map-marker-radius ")]),_vm._v(" "+_vm._s(_vm.trimmedName(map))+" ")],1)],1)}),0):_vm._e(),(item.showUrl)?_c('v-col',{attrs:{"align-self":"center","align":"center"}},[_c('v-btn',{staticClass:"mb-3",attrs:{"color":"secondary","width":"85%"},on:{"click":function($event){return _vm.sendLink(
                                item.urlDetails.urllist[0].link,
                                item.question,
                                item.urlDetails.urllist[0].redirect
                              )}}},[_c('v-icon',{attrs:{"left":"","dark":"","medium":""}},[_vm._v(" mdi-link-variant ")]),_vm._v(" "+_vm._s(item.urlDetails && item.urlDetails.urllist && item.urlDetails.urllist.length > 0 ? _vm.trimmedName(item.urlDetails.urllist[0].name) : "Go to Website")+" ")],1)],1):_vm._e(),(item.showForm)?_c('v-col',{attrs:{"align-self":"center","align":"center"}},[_c('v-btn',{staticClass:"mb-3",attrs:{"color":"secondary","width":"85%"},on:{"click":function($event){return _vm.showForm(item.formName)}}},[_c('v-icon',{attrs:{"left":"","dark":"","medium":""}},[_vm._v(" mdi-link-variant ")]),_vm._v(" "+_vm._s(item.formName)+" ")],1)],1):_vm._e()],1)],1)],1):_c('v-row',[_c('v-col',[_c('span',{domProps:{"innerHTML":_vm._s(item.answer)}})])],1)],1)],1)}),(_vm.matchingFaqs.length === 0)?_c('v-row',{staticClass:"mt-4",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"text-center pa-0",attrs:{"cols":"12"}},[_c('span',{staticClass:"text-h4"},[_vm._v(_vm._s(this.$i18n.t("noFaqsMatch")))])])],1):_vm._e()],2)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }