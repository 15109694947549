<template>
  <v-row justify="center" align="start">
    <v-col class="pt-10" cols="12" align="center">
      <span class="text-h2 font-weight-bold grey--text text--darken-3">
        {{ $t("default") }}
      </span>
    </v-col>
    <v-col cols="10" class="py-2">
      <v-row class="fill-height mt-6 mb-6" align="center" justify="center">
        <!-- Regular menu items -->
        <v-col
          align="center"
          :cols="menu.col"
          v-for="(page, index) in filteredPages"
          :key="index"
          class="mb-2"
        >
          <v-card
            @click="goto(page)"
            class="menuItem d-flex flex-column align-center justify-center"
            :width="menu.width"
            :height="menu.height"
            elevation="8"
            :ripple="false"
          >
            <v-img :src="page.displayIcon.url" :width="menu.imageWidth" contain>
            </v-img>
            <v-card-text class="text-h5 primary--text font-weight-bold">
              {{ page.pageName }}
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-divider class="pb-6 mx-10"></v-divider>
      <languageBar></languageBar>
    </v-col>

     <!-- height: 260,
        width: 275,
        col: 4,
        imageWidth: "55%", -->

    <!-- Payment Popup -->
    <v-dialog v-model="this.getPaymentPopUp" max-width="45%">
      <v-card class="pa-2">
        <div style="position: absolute; align-self: end; right: 0">
          <v-btn icon @click="showPaymentPopup" class="mr-5 mt-3">
            <v-icon color="red" x-large>mdi-window-close</v-icon>
          </v-btn>
        </div>
        <v-card-title class="justify-center headline">
          <span class="text-h5 font-weight-bold grey--text text--darken-3">
            Payments
          </span>
        </v-card-title>
        <v-divider class="mb-8"></v-divider>
        <v-container>
      <v-row
        justify="space-around"
        align="center"
        no-gutters
        class="mb-8 mx-8"
      >
          <v-col
            align="center"
            :cols="5"
            v-for="(page, index) in paymentPages"
            :key="index"
            class="mb-2"
          >
            <v-card
              @click="goto(page)"
              class="menuItem d-flex flex-column align-center justify-center"
              :width="350"
              :height="260"
              elevation="8"
            >
              <v-img
                :src="page.displayIcon.url"
                style="width: 40%;"
                contain
              >
              </v-img>
              <v-card-text class="text-h5 primary--text font-weight-bold">
                {{ page.pageName }}
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import languageBar from "@/components/languageBar";
import { mapGetters } from "vuex";

export default {
  name: "menuMatrix",
  components: {
    languageBar,
  },
  data() {
    return {
      // isPaymentPopupVisible: false,
      menu: {
        height: null,
        width: null,
        col: null,
        imageWidth: null,
      },
      noInternet : ['Maps']
    };
  },
  computed: {
    ...mapGetters([
      "getCMSlink",
      "getTouchCount",
      "isUserLooking",
      "getAvatarLangCodes",
      "getSessionId",
      "getKioskProfile",
      "getPaymentPopUp",
      "getNetworkConnection",
      "getGreetingStatus"
    ]),
    
    filteredPages() {
      return this.$t("homepages").filter(
        (page) =>
          page.alias !== "payment" ||
          (page.alias === "payment" &&
            page.pageName.toLowerCase() === "payments")
      );
    },
    paymentPages() {
      return this.$t("homepages").filter(
        (page) =>
          page.alias === "payment" && page.pageName.toLowerCase() !== "payments"
      );
    },
  },
  methods: {
    goto(page) {
      var date = new Date();
      // To start session using touch - when no face detected
            if (!this.isUserLooking) {
              this.$store.commit("setTouchCount", this.getTouchCount + 1);
              console.log(this.getTouchCount, " this is the touchCount in MM");
              if (this.getTouchCount === 1) {
                this.$store.dispatch("addSessionDataToStrapi", {
                  applicationId: this.getKioskProfile.data().applicationId,
                  actionType: "Session Started",
                  applicationSessionId: this.getSessionId,
                  timeStamp: date.toISOString(),
                  sessionPayload: {
                    module: "Default",
                    action: "Touch Detected",
                    response: "User Session Started",
                    timeStamp: date.toISOString(),
                  },
                });
              }
            }

      if (page.pageType.length !== 0) {
        switch (page.pageType[0].__typename) {
          case "ComponentHomepageNavigation":
            this.$store.commit("setRequestQna", false);
            this.$store.commit("setIsThinking", false);
            this.$store.commit("setSpeechRecognitionHandler", false);
            this.$router.push("/" + page.pageType[0].pathName.toLowerCase());
            this.$store.commit("setPageHeader", page.pageType[0].pageHeader);
            this.$store.commit("setQnaKeyword", "");

            // To start session using touch - when no face detected
            // if (!this.isUserLooking) {
            //   this.$store.commit("setTouchCount", this.getTouchCount + 1);
            //   console.log(this.getTouchCount, " this is the touchCount in MM");
            //   if (this.getTouchCount === 1) {
            //     this.$store.dispatch("addSessionDataToStrapi", {
            //       applicationId: this.getKioskProfile.data().applicationId,
            //       actionType: "Session Started",
            //       applicationSessionId: this.getSessionId,
            //       timeStamp: date.toISOString(),
            //       sessionPayload: {
            //         module: "Default",
            //         action: "Touch Detected",
            //         response: "User Session Started",
            //         timeStamp: date.toISOString(),
            //       },
            //     });
            //   }
            // }

            // Will be called irrespective of Touch Count
            this.$store.dispatch("addSessionDataToStrapi", {
              applicationId: this.getKioskProfile.data().applicationId,
              actionType: "Touch",
              applicationSessionId: this.getSessionId,
              timeStamp: date.toISOString(),
              sessionPayload: {
                module: "Home Page",
                action: "navigate",
                response: page.pageType[0].pathName,
                timeStamp: date.toISOString(),
              },
            });

            // this.$store.commit("setDefaultBubbleText", true);
            //this.$store.dispatch('avatarSpeak', page.speech)
            this.$store.commit("setTextBubbleText", page.speech);
            window.sayText(
              page.speech,
              this.getAvatarLangCodes.voiceId,
              this.getAvatarLangCodes.langId,
              this.getAvatarLangCodes.engineId
            );

            break;
          case "ComponentHomepageDialogBox":
            console.log("Opening Component Dialog Box : ");

            // To start session using touch - when no face detected

            if (page.pageType[0].type === "url") {
              // if(page.pageType[0].textList === 'https://chesco.maps.arcgis.com/apps/webappviewer/index.html?id=38fdd81ef9884a96be8c6dad38f6ec55'){
                this.$store.dispatch("addSessionDataToStrapi", {
                applicationId: this.getKioskProfile.data().applicationId,
                actionType: "Touch",
                applicationSessionId: this.getSessionId,
                timeStamp: date.toISOString(),
                sessionPayload: {
                  module: `Home Page`,
                  action: 'Open URL',
                  response: `${page.pageName}`,
                  timeStamp: date.toISOString(),
                },
              });
              
              if(this.getPaymentPopUp){
                this.showPaymentPopup();
              }
              // window.location.href = page.pageType[0].textList;
              
              if(this.getNetworkConnection){
                window.open(page.pageType[0].textList, '_blank')
              }
              else{
                const featureDisabledMsg = "This feature is currently unavailable due to an internet connectivity issue."
                // this.$store.dispatch('avatarSpeak', featureDisabledMsg)
                this.$store.commit('setTextBubbleText', featureDisabledMsg);
                

              }
              
            } else if (page.pageType[0].type === "textList") {              
              this.$store.dispatch('avatarSpeak', page.speech)
              this.$store.commit("setTextBubbleText", page.speech);
              this.showPaymentPopup();
              window.sayText(
                page.speech,
                this.getAvatarLangCodes.voiceId,
                this.getAvatarLangCodes.langId,
                this.getAvatarLangCodes.engineId
              );
            }

            break;
          default:
            break;
        }
      }
    },

    showPaymentPopup() {
      // this.isPaymentPopupVisible = !this.isPaymentPopupVisible;

      //true ka false false ka true
      if(this.getPaymentPopUp){
        this.$store.commit("setPaymentPopUp", false);
      }
      else{
        this.$store.commit("setPaymentPopUp", true);
      }

      if(!this.getPaymentPopUp){
        
        console.log("popup is visible");
        this.$store.commit("setDefaultBubbleText", true);
        this.$store.commit(
        "setTextBubbleText",
        "Please face towards the camera, tap on the microphone button, and ask a court-related question."
      );
      }
    },
  },
  mounted() {
    // if (this.$i18n.t('homepages').length <= 4) {
    //   this.menu = {
    //     height: 270,
    //     width: 280,
    //     col: 5,
    //     imageWidth: "60%"
    //   }
    // } else if (this.$i18n.t('homepages').length <= 6) {
    //   this.menu = {
    //     height: 260,
    //     width: 275,
    //     col: 4,
    //     imageWidth: "55%"
    //   }
    // }

    if (this.filteredPages.length <= 4) {
      this.menu = {
        height: 270,
        width: 280,
        col: 5,
        imageWidth: "60%",
      };
    } else if (this.filteredPages.length <= 6) {
      this.menu = {
        height: 260,
        width: 275,
        col: 4,
        imageWidth: "55%",
      };
    }
    else if (this.filteredPages.length <= 9) {
      this.menu = {
        height: 270,
        width: 280,
        col: 3,
        imageWidth: "60%",
      };
    }

    
  },
};
</script>

<style>
.menuItem {
  border: solid #2c6b87 !important;
}

.footer {
  bottom: 0 !important;
}
</style>
<i18n>
    {
      "en": {
          "default": "How Can I Help You?"
        },
      "es":{
          "default": "¿Le puedo ayudar en algo?"
        }
    }
  </i18n>
